<template>
  <b-row class="users">
    <b-col>
      <b-row class="border-bottom sticky-top page-header bg-white">
        <div class="d-flex align-items-center w-100 mx-3">
          <h5 class="text-uppercase p-0 my-3 mr-2">
            {{ $t("admin.creatorPage.title") }}
          </h5>

          <b-nav pills>
            <b-nav-item
              v-for="(tab, index) in tabs"
              :key="tab + index"
              :active="activeTab === tab.id"
              @click="activeTab = tab.id"
            >
              {{ tab.name }}
            </b-nav-item>
          </b-nav>

          <b-button
            class="text-uppercase p-1 ml-auto w-auto"
            @click="showCreatorModal"
          >
            {{ $t("admin.creatorPage.addButton") }}
          </b-button>
        </div>
      </b-row>

      <b-row>
        <div class="mb-1 position-absolute centered-element">
          <b-spinner label="Loading..." v-if="requestInProgress" />
        </div>

        <b-table
          :key="activeTab + key"
          id="table"
          hover
          ref="table"
          responsive
          :items="activeTable.options"
          :fields="tableFields"
          :per-page="activeTable.limit"
          class="small nowrap"
          :current-page="activeTable.page"
        >
          <template #cell(user)="{ item } = {}">
            <b-avatar :src="item.avatar" :to="item.name" size="sm" />
            <CLink :to="`/${item.username}`"> {{ item.name }}</CLink>
          </template>

          <template #cell(bio)="{ item } = {}">
            <div class="text-cell">{{ item.bio }}</div>
          </template>

          <template #cell(metadata)="{ item } = {}">
            <span class="d-flex flex-wrap">
              <template v-for="tags in item.metadata">
                <b-badge
                  v-for="(tag, index) in tags"
                  :key="tags + index"
                  pill
                  variant="warning"
                  class="mr-1 mb-1"
                >
                  {{ tag }}
                </b-badge>
                &nbsp;
              </template>
            </span>
          </template>

          <template #cell(status)="{ item } = {}">
            <b-badge
              :variant="accountStatus(item.parody_status).theme"
              class="mr-1 mb-1"
            >
              {{ accountStatus(item.parody_status).text }}
            </b-badge>
          </template>

          <template #cell(social_networks)="{ item } = {}">
            <span v-if="Object.keys(item.social_networks).length">
              <template
                v-for="[icon, link] in Object.entries(item.social_networks)"
              >
                <a :key="icon" :href="link" target="_blank" v-if="link">
                  <CIcon
                    class="social-icon"
                    :name="icon"
                    path="admin/creator"
                  />
                </a>
              </template>
            </span>
          </template>

          <template #cell(actions)="{ item } = {}">
            <div>
              <b-row clas="p-1 mr-1">
                <b-col
                  cols="12"
                  class="p-1"
                  v-if="activeTab === creatorTypes.pending && canModerate"
                >
                  <b-button
                    size="sm"
                    @click="
                      changeModerationStatus(item, moderationStatus.active)
                    "
                    class="mb-1"
                    variant="primary"
                    :disabled="item.parody_status == moderationStatus.active"
                  >
                    {{ $t("admin.creatorPage.buttonsAction.approve") }}
                  </b-button>
                </b-col>

                <b-col class="px-1">
                  <b-button
                    size="sm"
                    @click="editCreatorModal(item.id)"
                    class="p-0 mb-1"
                    variant="secondary"
                  >
                    {{ $t("admin.creatorPage.buttonsAction.edit") }}
                  </b-button>
                </b-col>

                <b-col class="px-1" v-if="canModerate">
                  <b-button
                    v-if="activeTab !== creatorTypes.rejected"
                    size="sm"
                    @click="
                      changeModerationStatus(item, moderationStatus.rejected)
                    "
                    class="p-0 mb-1"
                    variant="danger"
                    :disabled="item.parody_status == moderationStatus.rejected"
                  >
                    {{ $t("admin.creatorPage.buttonsAction.reject") }}
                  </b-button>

                  <b-button
                    v-else
                    size="sm"
                    @click="
                      changeModerationStatus(item, moderationStatus.active)
                    "
                    class="p-0"
                    variant="primary"
                    :disabled="item.parody_status == moderationStatus.active"
                  >
                    {{ $t("admin.creatorPage.buttonsAction.approve") }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </template>
        </b-table>
      </b-row>

      <b-row class="text-center m-3 d-block" v-if="activeTable.count">
        <b-pagination
          v-model="tabPagination[activeTab]"
          :total-rows="activeTable.count"
          :per-page="activeTable.limit"
          class="mt-1 mb-0"
          last-number
          @change="changePage"
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          first-number
        />
      </b-row>
    </b-col>

    <CreatorModals
      :modal-id="`creator-modal`"
      @hide="onHide"
      v-bind="{ userId, tableType: this.activeTab }"
    />
  </b-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { cleanObj } from "@/tools/helpers";
import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import settings from "@/mixins/settings";

import CreatorModals from "@/components/common/CreatorModals.vue";
import CLink from "@/features/ui/CLink.vue";
import CIcon from "@/features/ui/CIcon.vue";
import axios from "axios";

const creatorTypes = {
  pending: "1",
  rejected: "2",
  active: "3",
  all: "all",
};

const moderationStatus = {
  pending: 1,
  rejected: 2,
  active: 3,
};
export default {
  name: "PageCreators",
  components: {
    CLink,
    CreatorModals,
    CIcon,
    // vSelect,
  },
  mixins: [validationError, waitRequest, settings],
  data() {
    return {
      key: Math.random(),
      creatorTypes,
      moderationStatus,
      modalKey: Math.random(),
      userId: null,
      type: null,

      showModal: false,
      searchTerm: "",

      options: ["some", "other"],
      form: {
        name: "",
        options: [],
        tags: [],
      },

      tableFields: [
        {
          key: "id",
          label: this.$t("admin.creatorPage.table.pending.id"),
          tdClass: "full",
        },
        {
          key: "user",
          label: this.$t("admin.creatorPage.table.pending.user"),
          tdClass: "full",
        },
        {
          key: "username",
          label: this.$t("admin.creatorPage.table.pending.username"),
          tdClass: "full",
        },
        {
          key: "bio",
          label: this.$t("admin.creatorPage.table.pending.bio"),
          tdClass: "full",
        },
        {
          key: "metadata",
          label: this.$t("admin.creatorPage.table.pending.metadata"),
          tdClass: "full",
        },
        {
          key: "social_networks",
          label: this.$t("admin.creatorPage.table.pending.socials"),
          tdClass: "full",
        },
        {
          key: "status",
          label: this.$t("admin.creatorPage.table.pending.status"),
          tdClass: "full",
        },
        {
          key: "actions",
          label: this.$t("admin.creatorPage.table.pending.actions"),
          tdClass: "full",
        },
      ],

      activeTab: creatorTypes.pending,

      tabs: [
        { id: creatorTypes.pending, name: "PENDING APPROVAL" },
        { id: creatorTypes.rejected, name: "REJECTED" },
        { id: creatorTypes.active, name: "Active" },
      ],

      tabPagination: {
        [creatorTypes.pending]: 1,
        [creatorTypes.rejected]: 1,
        [creatorTypes.active]: 1,
      },
    };
  },

  computed: {
    ...mapGetters({
      creators: "creators/creators",
    }),

    canModerate() {
      return this.$store.state.currentUser.canModerate;
    },

    tables() {
      const pending = this.creators[creatorTypes.pending];
      const rejected = this.creators[creatorTypes.rejected];
      const active = this.creators[creatorTypes.active];

      return {
        [creatorTypes.pending]: {
          columns: pending,
          options: pending.value,
          count: pending.count,
          limit: pending.limit,
          page: pending.page,
        },
        [creatorTypes.rejected]: {
          columns: rejected,
          options: rejected.value,
          count: rejected.count,
          limit: rejected.limit,
          page: rejected.page,
        },
        [creatorTypes.active]: {
          columns: active,
          options: active.value,
          count: active.count,
          limit: active.limit,
          page: active.page,
        },
      };
    },

    activeTable() {
      const currentTable = this.tables[this.activeTab];
      return {
        ...currentTable,
        options: this.transformRowsTable(currentTable.options || []),
      };
    },
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      setCreators: "creators/setCreators",
      updateCreatorsList: "creators/updateCreatorsList",
      deleteCreator: "creators/deleteCreator",
      addToCreatorList: "creators/addToCreatorList",
    }),

    async initState() {
      await this.getSettingsCategories();
      await this.fetchCreator({ page: 1, status: creatorTypes.pending });
      await this.fetchCreator({ page: 1, status: creatorTypes.rejected });
      await this.fetchCreator({ page: 1, status: creatorTypes.active });
    },

    accountStatus(status) {
      const statusColorVariant = {
        1: "primary",
        2: "danger",
        3: "success",
      };
      return {
        text: this.settings?.parody_account_statuses?.[status] || "Pending",
        theme: statusColorVariant[status],
      };
    },

    showCreatorModal() {
      this.$bvModal.show("creator-modal");
    },

    editCreatorModal(user) {
      this.userId = user;
      this.type = this.activeTab;
      this.$bvModal.show(`creator-modal`);
    },

    onHide(data) {
      this.userId = null;
      this.type = null;

      if (!data.user) this.$bvModal.hide("creator-modal");

      if (data.type === "create") {
        this.addToCreatorList({ creators: data.user });
      }

      if (data.type === "edit") {
        this.updateCreatorsList({
          user: data.user,
          type: data.user.parody_status,
        });
      }

      this.$bvModal.hide("creator-modal");
    },

    createQueryConfig(config) {
      return {
        page: this.activeTable.page,
        search: this.searchTerm || undefined,
        status: this.activeTab || undefined,

        ...config,
      };
    },

    changePage(pageNumber) {
      if (this.requestInProgress) return;
      const query = this.createQueryConfig({ page: pageNumber });

      return this.fetchCreator(query);
    },

    fetchCreator(query) {
      if (this.requestInProgress) return;

      const url = "/parodies/list";
      const params = cleanObj(query);

      return this.waitRequest(() => {
        return this.$aGet(
          url,
          (data) => {
            this.setCreators({ creators: data, params: query });
          },
          console.log,
          this.checkErrors,
          params
        ).catch(this.checkErrors);
      });
    },

    changeModerationStatus(user, status) {
      if (this.requestInProgress) return;

      const url = `/parodies/moderation/${user.id}/${status}`;

      return this.waitRequest(() => {
        return axios({
          method: "POST",
          url: process.env.VUE_APP_API_URL + "/admin" + url,
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
          .then((response) => {
            this.updateCreatorsList({
              user: response.data?.user,
              type: this.activeTab,
              status,
            });

            this.deleteCreator({
              id: response.data?.user?.id,
              type: this.activeTab,
            });
            this.key = Math.random();
          })
          .catch(this.checkErrors);
      });
    },

    transformRowsTable(rows) {
      const networks = {
        instagram: "https://instagram.com/",
        reddit: "https://reddit.com/user/",
        tiktok: "https://tiktok.com/@",
        x: "https://twitter.com/",
      };
      return rows.map((row) => {
        const social_networks = Object.entries(row.social_networks || {})
          ?.filter((item) => Boolean(item[1]))
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: `${networks?.[key]}${value}`,
            }),
            {}
          );

        return {
          ...row,
          social_networks,
        };
      });
    },
  },
};
</script>

<style lang="scss">
.social-icon {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
}

.text-cell {
  display: block;
  min-width: 300px;
  white-space: normal;
}
</style>
