<template>
  <b-modal
    :id="modalId"
    @hide="onHide"
    size="lg"
    header-class="creator-modal__header"
    hide-footer
  >
    <template #modal-title>
      <span class="flex-grow-1 w-100">{{ title }} ADD PARODY CREATOR</span>
    </template>

    <template #modal-header-close>
      <b-button variant="primary" @click.stop="confirmModal">POST</b-button>
    </template>

    <b-form>
      <div class="mb-1 position-absolute centered-element">
        <b-spinner label="Loading..." v-if="requestInProgress" />
      </div>

      <b-row>
        <b-col>
          <b-form-group label="Name" label-for="name">
            <b-form-input
              v-model="form.name"
              type="text"
              name="name"
              placeholder="Creator name"
              v-validate="'required'"
            />

            <small class="text-danger" v-show="errors.has('name')">
              {{ errors.first("name") }}
            </small>
          </b-form-group>

          <b-form-group label="Username" label-for="username">
            <b-form-input
              v-model="form.username"
              type="text"
              name="username"
              placeholder="Creator username"
              v-validate="'required'"
            />

            <small class="text-danger" v-show="errors.has('username')">
              {{ errors.first("username") }}
            </small>
          </b-form-group>

          <b-form-group label="Password" label-for="password">
            <b-form-input
              v-model="form.password"
              type="text"
              name="password"
              placeholder="Creator password"
              v-validate="passwordValidationRules"
            />

            <small class="text-danger" v-show="errors.has('password')">
              {{ errors.first("password") }}
            </small>
          </b-form-group>

          <b-form-group label="Bio" label-for="bio">
            <b-form-textarea
              v-model="form.bio"
              type="text"
              name="bio"
              placeholder="Creator bio"
              v-validate="'required'"
            />

            <small class="text-danger" v-show="errors.has('bio')">
              {{ errors.first("bio") }}
            </small>
          </b-form-group>

          <!--  images  -->
          <b-row class="mb-2">
            <b-col md="4">
              <p class="text">Profile Picture</p>
              <div class="cover w-100 position-relative rounded-circle">
                <b-img
                  :src="preview.avatar"
                  v-if="preview.avatar"
                  class="position-relative"
                />

                <div class="buttons mr-0">
                  <b-button
                    variant="icon"
                    class="p-0"
                    @click.prevent="addImage('avatar')"
                  >
                    <i class="bi-image text-white" />
                  </b-button>

                  <b-button variant="icon" class="p-0" v-if="preview.avatar">
                    <i
                      class="bi-x-circle text-white"
                      @click.prevent="deleteImage('avatar')"
                    />
                  </b-button>
                </div>
              </div>

              <small class="text-danger" v-show="errors.has('avatar')">
                {{ errors.first("avatar") }}
              </small>
            </b-col>

            <b-col md="8">
              <p class="text">Header Image</p>
              <div class="cover w-100 position-relative">
                <b-img :src="preview.cover" v-if="preview.cover" />

                <div class="buttons mr-0">
                  <b-button
                    variant="icon"
                    class="p-0"
                    @click.prevent="addImage('cover')"
                  >
                    <i class="bi-image text-white" />
                  </b-button>

                  <b-button variant="icon" class="p-0" v-if="preview.cover">
                    <i
                      class="bi-x-circle text-white"
                      @click.prevent="deleteImage('cover')"
                    />
                  </b-button>
                </div>
              </div>

              <small class="text-danger" v-show="errors.has('cover')">
                {{ errors.first("cover") }}
              </small>
            </b-col>
          </b-row>

          <b-form-group label="Location" label-for="location">
            <v-select
              v-model="form.location"
              label="address"
              :options="locationList"
              :reduce="(option) => option"
              @search="onLocationInput"
            />

            <small class="text-danger" v-show="errors.has('location')">
              {{ errors.first("location") }}
            </small>
          </b-form-group>

          <b-form-group label="Email" label-for="email">
            <b-form-input
              v-model="form.email"
              type="email"
              name="email"
              placeholder="Creator email"
              v-validate="'required|email'"
            />

            <small class="text-danger" v-show="errors.has('email')">
              {{ errors.first("email") }}
            </small>
          </b-form-group>

          <b-row>
            <b-col>
              <b-form-group label="Instagram" label-for="instagram">
                <b-form-input
                  v-model="form.social_networks.instagram"
                  type="text"
                  name="instagram"
                  placeholder="IG username"
                />

                <small class="text-danger" v-show="errors.has('instagram')">
                  {{ errors.first("instagram") }}
                </small>
              </b-form-group>

              <b-form-group label="TikTok" label-for="tiktok">
                <b-form-input
                  v-model="form.social_networks.tiktok"
                  type="text"
                  name="tiktok"
                  placeholder="TikTok username"
                />

                <small class="text-danger" v-show="errors.has('tiktok')">
                  {{ errors.first("tiktok") }}
                </small>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="X (Twitter)" label-for="twitter">
                <b-form-input
                  v-model="form.social_networks.x"
                  type="text"
                  name="twitter"
                  placeholder="X username"
                />

                <small class="text-danger" v-show="errors.has('twitter')">
                  {{ errors.first("twitter") }}
                </small>
              </b-form-group>

              <b-form-group label="Reddit" label-for="reddit">
                <b-form-input
                  v-model="form.social_networks.reddit"
                  type="text"
                  name="reddit"
                  placeholder="Reddit username"
                />

                <small class="text-danger" v-show="errors.has('reddit')">
                  {{ errors.first("reddit") }}
                </small>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col>
          <b-form-group label="Sex">
            <b-form-radio-group v-model="form.sex">
              <b-form-radio
                v-for="sexOption in sexOptions"
                :key="sexOption.id"
                :value="sexOption.id"
              >
                {{ sexOption.label }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group label="Sexual Orientation">
            <b-form-radio-group v-model="form.orientation">
              <b-form-radio
                v-for="sexOrientationOption in sexOrientationOptions"
                :key="sexOrientationOption.id"
                :value="sexOrientationOption.id"
              >
                {{ sexOrientationOption.label }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group label="Race">
            <b-form-radio-group v-model="form.metadata.races">
              <b-form-radio
                v-for="raceOption in metadataOptions.races"
                :key="raceOption.id"
                :value="raceOption.id"
              >
                {{ raceOption.label }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group label="Hair Color">
            <b-form-radio-group v-model="form.metadata.hair_color">
              <b-form-radio
                v-for="hairColorOption in metadataOptions.hair_color"
                :key="hairColorOption.id"
                :value="hairColorOption.id"
              >
                {{ hairColorOption.label }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group label="Eye Color">
            <b-form-radio-group v-model="form.metadata.eye_color">
              <b-form-radio
                v-for="eyeColorOption in metadataOptions.eye_color"
                :key="eyeColorOption.id"
                :value="eyeColorOption.id"
              >
                {{ eyeColorOption.label }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group label="Additional Tags" label-for="tags">
            <b-form-tags
              class="creator-tag"
              input-id="tags"
              v-model="form.metadata.additionalTags"
              label="tags"
              name="metadata.tags"
              data-vv-as="tags"
              tag-pills
              size="lg"
              remove-on-delete
            />

            <small class="text-danger" v-show="errors.has('metadata.tags')">
              {{ errors.first("metadata.tags") }}
            </small>
          </b-form-group>

          <div>
            <p>Generated Metadata (for Recommendation)</p>
            <b-badge
              v-for="(tag, index) in tags"
              :key="tag + index"
              pill
              variant="warning"
              class="mr-1"
            >
              {{ tag }} </b-badge
            >&nbsp;
          </div>

          <p
            v-if="userId"
            role="button"
            class="delete-acc mt-3 ml-0"
            @click="deleteCreatorHandler"
          >
            Delete Parody Creator
          </p>
        </b-col>
      </b-row>

      <input
        type="file"
        name="avatar"
        :accept="availableImagesTypes"
        ref="avatar"
        hidden
        @change="handleFileUpload"
      />
      <input
        type="file"
        name="cover"
        :accept="availableImagesTypes"
        ref="cover"
        hidden
        @change="handleFileUpload"
      />
    </b-form>
  </b-modal>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import axios from "axios";
import { objectToFormData } from "@/tools/helpers";

import settings from "@/mixins/settings";
import location from "@/mixins/location";
import { availableImagesTypes } from "@/config/video";
import { mapMutations } from "vuex";
import vSelect from "vue-select";

export default {
  name: "CreatorModals",
  components: { vSelect },
  mixins: [waitRequest, validationError, settings, location],
  props: {
    modalId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    userId: {
      type: [String, Number],
      default: undefined,
    },
    tableType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: null,
      searchTerm: "",
      location: "",
      showDropdown: false,

      locationList: [
        {
          lat: 40.7127281,
          lon: -74.0060152,
          address: "New York, NY, United States of America",
        },
      ],

      form: {
        name: "",
        username: "",
        password: "",
        bio: "",
        avatar: "",
        cover: "",
        email: "",

        location: null,

        social_networks: {
          instagram: "",
          x: "",
          tiktok: "",
          reddit: "",
        },

        sex: 1, // options 1 - female, 2 - male
        orientation: 1, //  options 1 - Straight, 2 - Lesbian, 3 - Gay

        metadata: {
          races: "white",
          hair_color: "black",
          eye_color: "brown",
          additionalTags: [],
        },
      },

      sexOptions: [],
      sexOrientationOptions: [],

      metadataOptions: {
        eye_color: [],
        hair_color: [],
        races: [],
      },

      selectedAvatarFile: null,
      preview: {
        avatar: null,
        cover: null,
      },
    };
  },
  computed: {
    availableImagesTypes() {
      return availableImagesTypes;
    },
    tags() {
      let tags = [];
      const { hair_color, eye_color, races } = this.form.metadata;

      const tagsList = [
        ["hair", hair_color],
        ["eye", eye_color],
        ["races", races],
      ].map(([key, tag]) => {
        if (!tag) return "";

        if (tag.includes("_")) {
          const startTag = tag.split("_").join("-");
          return `${startTag}-${key}`;
        }

        return `${tag}-${key}`;
      });

      tags.push(...tagsList);

      this.form.metadata.additionalTags.forEach((item) => {
        tags.push(item);
      });

      return tags;
    },

    passwordValidationRules() {
      let rules = {
        required: !this.userId,
      };

      if (!this.userId) {
        rules["min"] = 8;
      }

      return rules;
    },
  },

  watch: {
    userId(id) {
      if (id) this.fetchUser(id);
    },
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      deleteCreator: "creators/deleteCreator",
    }),
    // modal methods
    async initState() {
      await this.getSettingsMetadata();
      await this.getSettingsSex();
      await this.getSettingsCategories();

      this.setOptionsSettings();
    },

    setOptionsSettings() {
      this.sexOrientationOptions = Object.entries(
        this.sexSettings.orientation
      ).map(([id, label]) => ({ id, label }));

      this.sexOptions = Object.entries(this.sexSettings.sex)
        .map(([id, label]) => ({ id, label }))
        .slice(0, 2); // only woman and man

      for (let key in this.metadataSettings) {
        let arr = [];
        for (let metaKey in this.metadataSettings[key]) {
          arr.push({ id: metaKey, label: this.metadataSettings[key][metaKey] });
        }

        this.metadataOptions[key] = arr;
        arr = [];
      }
    },

    hideModal() {
      this.$bvModal.hide(this.modalId);
    },

    initFormData() {
      if (!this.userId) return;

      const {
        name,
        username,
        bio,
        location,
        social_networks,
        cover,
        avatar,
        sex,
        orientation,
        email,
        metadata: {
          race = [""],
          hair_color = [""],
          eye_color = [""],
          tags = [""],
        } = {},
      } = this.user;

      this.preview = {
        avatar,
        cover,
      };

      this.form = {
        name,
        username,
        bio,
        email,

        location,

        social_networks,

        sex, // options 1 - female, 2 - male
        orientation, //  options 1 - Straight, 2 - Lesbian, 3 - Gay, 4 - Bisexual

        metadata: {
          races: race[0],
          hair_color: hair_color[0],
          eye_color: eye_color[0],
          additionalTags: tags,
        },
      };
    },

    resetFormData() {
      this.form = {
        name: "",
        username: "",
        password: "",
        bio: "",
        avatar: "",
        cover: "",
        email: "",

        location: null,

        social_networks: {
          instagram: "",
          x: "",
          tiktok: "",
          reddit: "",
        },

        sex: 1, // options 1 - female, 2 - male
        orientation: 1, //  options 1 - Straight, 2 - Lesbian, 3 - Gay, 4 - Bisexual

        metadata: {
          races: "white",
          hair_color: "black",
          eye_color: "brown",
          additionalTags: [],
        },
      };

      this.preview = {
        avatar: null,
        cover: null,
      };
    },

    // send form
    async confirmModal() {
      const isValid = await this.$validator.validate();

      if (this.requestInProgress || !isValid) return;

      const { hair_color, eye_color, races, additionalTags } =
        this.form.metadata;


      const metadata = {
        race: [races],
        hair_color: [hair_color],
        eye_color: [eye_color],
        tags: [...additionalTags],
      };

      const data = {
        ...this.form,
        social_networks: JSON.stringify(this.form.social_networks),
        metadata: JSON.stringify(metadata),
        location: JSON.stringify(this.form.location),
      };

      const formData = objectToFormData(data);

      if (this.userId) return this.updateUser(formData, this.userId);

      this.createUser(formData);
    },

    createUser(formData) {
      const url = "/parodies";

      return this.waitRequest(() => {
        return axios({
          method: "POST",
          url: process.env.VUE_APP_API_URL + "/admin" + url,
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
          .then((response) =>
            this.onHide({ type: "create", user: response.data.user })
          )
          .catch(this.checkErrors);
      });
    },

    updateUser(formData, id) {
      const url = `/parodies/${id}`;

      return this.waitRequest(() => {
        return axios({
          method: "POST",
          url: process.env.VUE_APP_API_URL + "/admin" + url,
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
          .then((response) =>
            this.onHide({ type: "edit", user: response.data.user })
          )
          .catch(this.checkErrors);
      });
    },

    successConfirmModal() {
      this.onHide("create");
    },

    failureConfirmModal(error) {
      console.log("error", error);
    },

    onHide(data) {
      this.$emit("hide", data);
      this.resetFormData();
    },

    // form methods
    deleteCreatorHandler() {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete everything.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "danger",
          modalClass: "modal-danger",
          okTitle: "Yes",
          cancelTitle: "No",
          headerClass: "creator-modal__header",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.delete();
          }
        });
    },

    delete() {
      if (this.requestInProgress) return;
      const url = `/parodies/${this.userId}`;

      return this.waitRequest(() => {
        return axios({
          method: "DELETE",
          url: process.env.VUE_APP_API_URL + "/admin" + url,
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
          .then(() => {
            this.deleteCreator({
              id: this.userId,
              type: this.tableType,
            });
            this.onHide({ type: "edit" });
          })
          .catch(this.checkErrors);
      });
    },

    // image uplader methods
    handleFileUpload(event) {
      const name = event.target.name;
      const file = event.target.files[0];
      this.form[name] = file;

      this.createImagePreview(file, name);
    },

    createImagePreview(file, name) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.preview[name] = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    addImage(type) {
      this.$refs[type].click();
    },

    deleteImage(type) {
      this.form[type] = "";
      this.preview[type] = "";
    },

    fetchUser(id) {
      return this.waitRequest(() => {
        const url = `/parodies/${id}`;

        return this.$aGet(
          url,
          (data) => {
            this.user = data.user;
            this.initFormData();
          },
          (error) => console.log(error),
          this.checkErrors
        );
      });
    },
  },
};
</script>

<style lang="scss">
.cover,
.avatar {
  z-index: 2;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.2;
    pointer-events: none;
    content: "";
    z-index: 3;
  }
}

.cover {
  height: 92px;

  img {
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.buttons {
  position: absolute;
  bottom: 0%;
  right: 0%;
  //transform: translate(-50%, -50%);
  z-index: 11;
}

.avatar {
  height: 92px;
  width: 92px !important;
  margin-top: -50px;
  z-index: 3;
  &:after {
    border-radius: 50%;
  }
}

.delete-acc {
  margin-left: 1rem;
  color: red;
  bottom: 0;
  left: 0;
}
</style>
